.internal-footer {
  padding: 36px 30px !important;
  background-color: #414046 !important;
  width: 100%;
  right: 0px;
  bottom: 0px;
  small {
    color: white;
  }
}

@font-family: Calibri;@primary-color: #65bfb1;@primary-color-hover: #316772;@success-color: #95c46f;@warning-color: #eda645;@error-color: #dd5358;@background-color: #f5f7f8;@disabled-bg: #8f9396;@text-color: #414046;@disabled-color: #8f9396;@layout-header-background: #fff;@font-size-base: 14px;@heading-1-size: (@font-size-base * 3) + 4px;@heading-2-size: (@font-size-base * 2) - 4px;@heading-3-size: @font-size-base + 6px;@heading-4-size: (@font-size-base * 1) + 4px;@btn-font-size-sm: (@font-size-base) + 8px;@btn-font-size-lg: (@font-size-base) + 4px;@border-radius-base: 4px;@btn-height-base: 54px;@form-item-margin-bottom: 20px;@layout-body-background: @background-color;@layout-header-padding: 0px 30px;@layout-header-height: 80px;@modal-mask-bg: rgba(0, 0, 0, 0.7);